$icon-size: 20px;
.icon-styleguide
{
	display: inline-block;
	margin: 20px 10px;
	text-align: center;
	vertical-align: top;
	width: 200px;

	.icon
	{
		&:before
		{
			display: block;
			font-size: 30px;
		}
	}

	.code
	{
		border: 1px solid #ddd;
		border-radius: 3px;
		background: #f5f5f5;
		display: inline-block;
		font-size: 12px;
		margin: 10px 0 0;
		padding: 3px;
	}

	&:hover
	{
		//cursor: pointer;
		.code { background: #2C3E50; color: $white; }
	}
}

@mixin make-icon
{
	font-size: $icon-size;
	font-family: 'iconfont';
	speak: none;
	font-style: normal;
	font-weight: normal;
	font-variant: normal;
	text-transform: none;
	line-height: 1;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
}

.icon:before,
[class^="icon-"]:before,
[class*=" icon-"]:before
{
	@include make-icon;
}

.icon-md:before 	{ font-size:  $icon-size * 1.5; }
.icon-lg:before 	{ font-size:  $icon-size * 1.8; }
.icon-xl:before 	{ font-size:  $icon-size * 2; }
.icon-xxl:before 	{ font-size:  $icon-size * 3; }



@mixin icon-close { content: "\EA01"; }

.icon-close:before { @include icon-close; }

@mixin icon-gimmick { content: "\EA02"; }

.icon-gimmick:before { @include icon-gimmick; }

@mixin icon-cooker { content: "\EA03"; }

.icon-cooker:before { @include icon-cooker; }

@mixin icon-waiter { content: "\EA04"; }

.icon-waiter:before { @include icon-waiter; }

@mixin icon-arrow-left { content: "\EA05"; }

.icon-arrow-left:before { @include icon-arrow-left; }

@mixin icon-arrow-right { content: "\EA06"; }

.icon-arrow-right:before { @include icon-arrow-right; }

@mixin icon-chevron-right { content: "\EA07"; }

.icon-chevron-right:before { @include icon-chevron-right; }