$white: #ffffff;
$black: #11141D;
$black-blue: #171923;

$grey-light: #eeeeee;
$grey-lighter: #f4f4f4;
$grey-lightest: #f8f8f8;
$grey: #999999;
$grey-dark: #58595C;
$grey-darker: #333333;

$light-green: #91BE3D;
$light-green-light: lighten($light-green, 10%);
$light-green-lighter: lighten($light-green, 25%);
$light-green-lightest: lighten($light-green, 50%);
$light-green-dark: darken($light-green, 10%);
$light-green-darker: darken($light-green, 25%);
$light-green-darkest: darken($light-green, 50%);

$dark-green: #73972D;
$dark-green-light: lighten($dark-green, 10%);
$dark-green-lighter: lighten($dark-green, 25%);
$dark-green-lightest: lighten($dark-green, 50%);
$dark-green-dark: darken($dark-green, 10%);
$dark-green-darker: darken($dark-green, 25%);
$dark-green-darkest: darken($dark-green, 50%);

$blue: #EFF5F9;
$blue-light: lighten($blue, 10%);
$blue-lighter: lighten($blue, 25%);
$blue-lightest: lighten($blue, 50%);
$blue-dark: darken($blue, 10%);
$blue-darker: darken($blue, 25%);
$blue-darkest: darken($blue, 50%);