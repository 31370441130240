//-----------------------------------------------------------------------------
// Variables
//----------
// Change them if you aren't using foundation 6+
//-----------------------------------------------------------------------------


%circle {
  border-radius: 100%;
}

%transition-ease {
  transition: all .2s ease;
}

%transition-ease-long {
  transition: all .4s ease;
}

// element full cover (with absolute position)
%abs-full {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  width: 100%;
  height: 100%;
}

//-----------------------------------------------------------------------------
//-----------------------------------------------------------------------------
// EXTENDS
//-----------------------------------------------------------------------------
//-----------------------------------------------------------------------------
.overlink {
  @extend %abs-full;
  display: block;
}
