.type-confirmation {
    .confirmation .row {
        padding: 20px;
        > p {
            text-align: center;
            margin-bottom: 10px;
        }
    }
    strong,
    p {
        font-size: 14px;
    }
    strong {
        display: block;
    }
    p {
        margin: 0;
    }
    li {
        margin-bottom: 1rem;
        p a {
            display: inline-block;
            color: $dark-green;
            text-decoration: underline;
            @extend %transition-ease;
            &:hover, &:focus, &:active {
                color: $dark-green-dark;
            }
        }
    }
    .address {
        text-align: center;
        margin-bottom: 1rem;
    }
    @include breakpoint (medium) {
        .confirmation .row {
            padding: 100px 20px;
            > p {
                text-align: left;
            }
        }
        strong,
        p {
            font-size: 18px;
            line-height: 24px;
        }
    }
    @include breakpoint (large) {
        .confirmation .row {
            padding: 100px 0;
        }
    }
}