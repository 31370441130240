body {
    background: url(../images/bg.png) no-repeat;
    background-size: cover;
}

.off-canvas-content {
    padding-top: 170px;
    @include breakpoint (medium) {
        padding-top: 112px;
    }
}

strong,
b {
    font-family: $font-b;
}

em {
    font-family: $font-it;
}

h1, h2, h3, h4 {
    font-family: $ff-asap;
    color: $light-green;
}

h1 {
    line-height: 1;
    margin: 20px;
    text-align: center;
    @include breakpoint (medium) {
        margin: 190px 20px 10px 20px;
        font-size: 48px;
        line-height: inherit;
    }
    @include breakpoint (large) {
        margin: 190px 0 0 0;
        line-height: 1;
    }
}

h2 {
    @include breakpoint (medium) {
        font-size: 48px;
    }
}

h3 {
    font-size: 16px;
    @include breakpoint (medium) {
        font-size: 25px;
    }
}

h4 {
    @include breakpoint (medium) {
        font-size: 24px;
    }
}