@import url('https://fonts.googleapis.com/css?family=Asap:700');

@font-face {
    font-family:aller_regular;
    src:url(../fonts/aller_rg-webfont.eot);
    src:url(../fonts/aller_rg-webfont.eot?#iefix) format("embedded-opentype"),
    url(../fonts/aller_rg-webfont.woff2) format("woff2"),
    url(../fonts/aller_rg-webfont.woff) format("woff"),
    url(../fonts/aller_rg-webfont.ttf) format("truetype"),
    url(../fonts/aller_rg-webfont.svg#aller_rg-webfont) format("svg");
    font-weight:400;font-style:normal
}
@font-face {
    font-family:aller_rg_italic;
    src:url(../fonts/aller_it-webfont.eot);
    src:url(../fonts/aller_it-webfont.eot?#iefix) format("embedded-opentype"),
    url(../fonts/aller_it-webfont.woff2) format("woff2"),
    url(../fonts/aller_it-webfont.woff) format("woff"),
    url(../fonts/aller_it-webfont.ttf) format("truetype"),
    url(../fonts/aller_it-webfont.svg#aller_rg-webfont) format("svg");
    font-weight:400;font-style:normal
}
@font-face {
    font-family:aller_rg_bold;
    src:url(../fonts/aller_bd-webfont.eot);
    src:url(../fonts/aller_bd-webfont.eot?#iefix) format("embedded-opentype"),
    url(../fonts/aller_bd-webfont.woff2) format("woff2"),
    url(../fonts/aller_bd-webfont.woff) format("woff"),
    url(../fonts/aller_bd-webfont.ttf) format("truetype"),
    url(../fonts/aller_bd-webfont.svg#aller_rg-webfont) format("svg");
    font-weight:400;font-style:normal
}
@font-face {
    font-family:aller_bold_italic;
    src:url(../fonts/aller_bdit-webfont.eot);
    src:url(../fonts/aller_bdit-webfont.eot?#iefix) format("embedded-opentype"),
    url(../fonts/aller_bdit-webfont.woff2) format("woff2"),
    url(../fonts/aller_bdit-webfont.woff) format("woff"),
    url(../fonts/aller_bdit-webfont.ttf) format("truetype"),
    url(../fonts/aller_bdit-webfont.svg#aller_rg-webfont) format("svg");
    font-weight:400;font-style:normal
}
@font-face {
    font-family:aller_light_italic;
    src:url(../fonts/aller_ltit-webfont.eot);
    src:url(../fonts/aller_ltit-webfont.eot?#iefix) format("embedded-opentype"),
    url(../fonts/aller_ltit-webfont.woff2) format("woff2"),
    url(../fonts/aller_ltit-webfont.woff) format("woff"),
    url(../fonts/aller_ltit-webfont.ttf) format("truetype"),
    url(../fonts/aller_ltit-webfont.svg#aller_rg-webfont) format("svg");
    font-weight:400;font-style:normal
}

@font-face {
  font-family: 'iconfont';
  src: url('../fonts/iconfont.eot');
  src: url('../fonts/iconfont.eot?#iefix') format('embedded-opentype'),
  url('../fonts/iconfont.woff') format('woff'),
  url('../fonts/iconfont.ttf') format('truetype'),
  url('../fonts/iconfont.svg#iconfont') format('svg');
  font-weight: normal;
  font-style: normal;
}

$ff-base: 'Helvetica Neue', Helvetica, Roboto, Arial, sans-serif;

$ff-aller: aller_regular;
$ff-aller-italic: aller_rg_italic;
$ff-aller-regular-bold: aller_rg_bold;
$ff-aller-bold-italic: aller_bold_italic;
$ff-aller-light-italic: aller_light_italic;

$ff-asap: 'Asap';

$font: $ff-aller, $ff-base;
$font-it: $ff-aller-italic;
$font-b: $ff-aller-regular-bold;
$font-b-it: $ff-aller-bold-italic;
$font-light-it: $ff-aller-light-italic;