.stack {
    &.training-type {
        background-image: url(../images/bg.png), linear-gradient(to bottom, rgba(255,255,255,0) 0%,rgba(255,255,255,0) 20%,rgba($blue,1) 100%);
        background-repeat: no-repeat;
        background-size: cover;
        .intro {
            h1 + p {
                text-align: center;
                line-height: 18px;
                margin-bottom: 40px;
                &:after {
                    @include make-icon;
                    @include icon-gimmick;
                    display: block;
                    color: $dark-green;
                    margin-top: 25px;
                }
                @include breakpoint (medium) {
                    margin-bottom: 30px;
                    font-size: 24px;
                    line-height: inherit;
                }
                @include breakpoint (large) {
                    &:after {
                        font-size: 30px;
                    }
                }
            }
            .quote {
                text-align: center;
                margin: 20px;
                p {
                    line-height: 20px;
                    margin: 0;
                }
                em {
                    display: block;
                    margin-top: 30px;
                }
                @include breakpoint (medium) {
                    margin: 0 20px 85px 20px;
                    max-width: 878px;
                    p {
                        font-size: 18px;
                        line-height: 24px;
                    }
                    em {
                        font-size: 18px;
                    }
                }
                @include breakpoint (large) {
                    margin: 0 auto 85px auto;
                }
            }
            iframe {
                border: 0;
                width: calc(100% - 40px);
                display: block;
                margin: 0 auto;
                height: 200px;
                @include breakpoint (medium) {
                    height: 658px;
                }
                @include breakpoint (large) {
                    width: 100%;
                }
            }
        }
        .training {
            padding: 20px 0;
            h2 {
                text-align: center;
                &:after {
                    @include make-icon;
                    @include icon-gimmick;
                    display: block;
                    color: $dark-green;
                    margin-top: 10px;
                }
            }
            h3 {
                text-align: center;
                color: $dark-green;
                text-transform: uppercase;
            }
            >p,
            .training-type p {
                display: inline-block;
                font-size: 14px;
                margin: 0 20px;
                padding: 0;
                left: 0;
                text-align: center;
            }
            .training-type {
                margin: 20px 20px 0 20px;
                .columns {
                    background: $white;
                    padding: 20px;
                    position: relative;
                    padding-top: 183px;
                    h4 {
                        text-align: center;
                    }
                    p {
                        text-align: center;
                        line-height: 20px;
                        font-size: 15px;
                        margin: 0 10px 40px 10px;
                    }
                    ul {
                        li {
                            font-size: 14px;
                        }
                    }
                    &:before,
                    &:after {
                        position: absolute;
                        top: 20px;
                        left: 50%;
                        transform: translate(-50%, 0);
                    }
                    &:before {
                        content: '';
                        display: block;
                        background: $blue;
                        width: 143px;
                        height: 143px;
                        @extend %circle;
                    }
                    &:after {
                        @include make-icon;
                        @include icon-cooker;
                        font-size: 80px;
                        color: $light-green;
                        top: 50px;
                    }
                    &+.columns {
                        margin-top: 20px;
                    }
                }
                .cooker {
                    &:after {
                        @include icon-cooker;
                    }
                }
                .waiter {
                    &:after {
                        @include icon-waiter;
                    }
                }
                p {
                    margin: 20px 20px 0 20px;
                }
                @include breakpoint (medium) {
                    font-size: 16px;
                }
            }
            @include breakpoint (medium) {
                padding: 120px 0 75px 0;
                h2 {
                    &:after {
                        margin-top: 25px;
                    }
                }
                .training-type .columns {
                    ul {
                        li {
                            font-size: 18px;
                        }
                    }
                    &+.columns {
                        margin-top: 0;
                    }
                }
                >p {
                    display: block;
                    margin: 0 auto;
                    max-width: 878px;
                }
                >p,
                .training-type p {
                    font-size: 18px;
                    line-height: 24px;
                }
                .training-type .columns p {
                    font-size: 18px;
                    line-height: 24px;
                }
            }
            @include breakpoint (large) {
                h2 {
                    margin-bottom: 48px;
                    &:after {
                        margin-top: 0;
                        font-size: 30px;
                    }
                }
                h3 {
                    margin-bottom: 15px;
                }
                .training-type {
                    display: flex;
                    flex-flow: row wrap;
                    align-items: stretch;
                    margin-top: 95px;
                    p {
                        margin-top: 75px;
                    }
                    .columns {
                        width: calc(50% - 15px);
                        text-align: center;
                        p {
                            max-width: 270px;
                        }
                        ul {
                            text-align: left;
                        }
                        &+.columns {
                            margin-left: 30px;
                        }
                    }
                }
            }
        }
    }
    &.training-planning {
        padding: 20px;
        background: $light-green;
        h2,
        p {
            text-align: center;
            color: $white;
        }
        p {
            line-height: 20px;
            font-size: 15px;
            margin: 0 20px;
            &:after {
                @include make-icon;
                @include icon-gimmick;
                display: block;
                color: $white;
                margin: 10px 0 45px 0;
            }
        }
        .training-planning {
            display: inline-block;
            width: 100%;
        }
        .planning {
            background: $white;
            padding: 183px 20px 20px 20px;
            position: relative;
            text-align: center;
            h4 {
                margin: -5px;
            }
            strong {
                font-size: 14px;
            }
            &:before,
            &:after {
                position: absolute;
                top: 20px;
                left: 50%;
                transform: translate(-50%, 0);
            }
            &:before {
                content: '';
                display: block;
                background: $light-green;
                width: 143px;
                height: 143px;
                @extend %circle;
            }
            &:after {
                @include make-icon;
                @include icon-cooker;
                font-size: 80px;
                color: $white;
                top: 50px;
            }
            &+.planning {
                margin-top: 20px;
            }
        }
        @include breakpoint (medium) {
            padding: 120px 20px;
            p {
                font-size: 21px;
                line-height: inherit;
            }
            .planning {
                strong {
                    font-size: 18px;
                }
            }
        }
        @include breakpoint (large) {
            padding: 120px 0;
            h2 {
                margin: 0;
            }
            p {
                &:after {
                    font-size: 30px;
                }
            }
            .planning {
                width: calc(50% - 15px);
                &+.planning {
                    margin-top: 0;
                }
            }
        }
    }
    &.faq,
    &.contact {
        background: $blue;
    }
    &.contact {
        padding: 20px;
        h2 {
            text-align: center;
            &:after {
                @include make-icon;
                @include icon-gimmick;
                display: block;
                color: $dark-green;
                margin-top: 10px;
            }
        }
        .infos {
            display: inline-block;
            width: 100%;
            > div {
                padding: 0;
            }
            p {
                line-height: 20px;
                font-size: 15px;
            }
            .left {
                >strong {
                    display: block;
                    line-height: 20px;
                    font-size: 15px;
                    margin: 20px 0 30px 0;
                }
                a {
                    display: block;
                    color: $black-blue;
                    line-height: 20px;
                    font-size: 15px;
                    @extend %transition-ease;
                    strong {
                        display: inline-block;
                        color: $light-green;
                        @extend %transition-ease;
                    }
                    &:hover, &:focus, &:active {
                        color: $dark-green;
                        strong {
                            color: $dark-green;
                        }
                    }
                    &:nth-of-type(3) {
                        color: $light-green;
                        &:hover, &:focus, &:active {
                            color: $dark-green;
                        }
                    }
                }
            }
            .right {
                margin-top: 20px;
                iframe {
                    width: 100%;
                }
            }
        }
        @include breakpoint (medium) {
            padding: 120px 20px 75px 20px;
            p {
                font-size: 18px;
                line-height: 24px;
            }
            .infos {
                margin-top: 65px;
                .left {
                    >strong {
                        margin-top: 0;
                    }
                    >strong,
                    a {
                        font-size: 18px;
                        line-height: 24px;
                    }
                }
            }
        }
        @include breakpoint (large) {
            padding: 120px 0 75px 0;
            h2 {
                &:after {
                    font-size: 30px;
                }
            }
            .infos {
                display: flex;
                flex-flow: row wrap;
                align-items: stretch;
                .left {
                    padding: 100px 50px 100px 140px;
                }
                .right {
                    margin-top: 0;
                    text-align: right;
                    iframe {
                        width: 670px;
                    }
                }
            }
        }
    }
    &.galerie {
        padding: 20px;
        background: $white;
        h2 {
            text-align: center;
            &:after {
                @include make-icon;
                @include icon-gimmick;
                display: block;
                color: $dark-green;
                margin-top: 10px;
            }
        }
        .orbit {
            margin-top: 40px;
            .orbit-previous,
            .orbit-next {
                display: inline-block;
                width: 40px;
                height: 40px;
                left: 15px;
                cursor: pointer;
                z-index: 1;
                &:hover, &:focus, &:active {
                    background: transparent;
                }
                &:before,
                &:after {
                    position: absolute;
                    top: 50%;
                    left: 0;
                    transform: translate(0, -50%);
                }
                &:before {
                    content: '';
                    display: inline-block;
                    width: 40px;
                    height: 40px;
                    background: $light-green;
                    @extend %circle;
                }
                &:after {
                    @include make-icon;
                    @include icon-chevron-right;
                    color: $white;
                    left: 9px;
                    transform: translate(0, -50%) rotate(180deg);
                }
            }
            .orbit-next {
                left: auto;
                right: 15px;
                &:before,
                &:after {
                    left: auto;
                    right: 0;
                }
                &:after {
                    transform: translate(0, -50%);
                    left: auto;
                    right: 9px;
                }
            }
        }
        @include breakpoint (medium) {
            padding: 75px 20px 100px 20px;
        }
        @include breakpoint (large) {
            padding: 120px 0 100px 0;
            h2:after {
                font-size: 30px;
            }
        }
    }
    &.faq {
        padding: 20px;
        h2 {
            text-align: center;
            &:after {
                @include make-icon;
                @include icon-gimmick;
                display: block;
                color: $dark-green;
                margin-top: 10px;
            }
        }
        ul,
        ul li,
        ul li a,
        ul li div {
            background: transparent;
            border: 0;
        }
        ul li {
            border-bottom: 1px solid $white;
            a {
                color: $black-blue;
                font-size: 14px;
                line-height: 18px;
                padding : 15px 0 15px 35px;
                @extend %transition-ease;
                &:before {
                    left: 6px;
                    top: 52%;
                    z-index: 2;
                    color: $white;
                }
                &:after {
                    content: '';
                    display: inline-block;
                    background: $light-green;
                    width: 20px;
                    height: 20px;
                    position: absolute;
                    top: 50%;
                    left: 0;
                    margin-top: -0.5rem;
                    @extend %circle;
                }
            }
            div p {
                font-size: 14px;
                line-height: 18px;
                font-style: italic;
            }
            &.is-active {
                a {
                    font-weight: bold;
                    padding: 15px 0 2px 35px;
                    &:before {
                        top: 51%;
                        left: 7px;
                    }
                }
                div {
                    padding: 0 0 0 35px;
                }
            }
            &:last-child {
                border-bottom: 0;
                &.is-active div {
                    border-bottom: 0;
                }
            }
            &:last-child:not(.is-active)>.accordion-title {
                border: 0;
            }
        }
        @include breakpoint (medium) {
            padding: 120px 20px 100px 20px;
            ul li {
                a,
                div p {
                    font-size: 18px;
                    line-height: 24px;
                }
                a {
                    &:before {
                        left: 5px;
                        top: 48%;
                    }
                }
                &.is-active {
                    a:before {
                        top: 44%;
                        left: 6px;
                    }
                }
            }
        }
        @include breakpoint (large) {
            h2 {
                margin-bottom: 70px;
                &:after {
                    font-size: 30px;
                }
            }
        }
    }
}

footer {
    background: $white;
    padding: 20px;
    .logo {
        img {
            width: 168px;
        }
    }
    .copyright {
        font-size: 15px;
        line-height: 20px;
        margin-top: 30px;
        color: $black-blue;
    }
    @include breakpoint (medium) {
        padding: 75px 20px 20px 20px;
        .copyright {
            font-size: 18px;
            line-height: 24px;
        }
    }
    @include breakpoint (large) {
        padding: 75px 0 20px 0;
    }
}