// WRAPPER
.header-wrapper {
    background: $white;
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    z-index: 10;
    box-shadow: 0px 0px 10px 0px rgba(0,0,0,.1);
    @extend %transition-ease-long;
    .l-header {
        @media screen and (min-width: 321px) and (max-width: 639px) {
            width: 310px;
        }
        @include breakpoint (medium) {
            display: flex; 
            flex-flow: row wrap;
            align-items: center;
            padding: 20px 0;
        }
    }
}

// BURGER
.toggle-menu-wrapper {
    margin: 20px 0 0 0;
    text-align: right;
    @include breakpoint (medium) {
        text-align: left;
        margin: 0;
    }
    @include breakpoint (xlarge) {
        padding: 0;
    }
}
.menu-toggle {
    display: inline-block;
    background: $dark-green;
    width: 39px;
    height: 39px;
    padding: 12px 9px;
    margin-right: 5px;
    @extend %transition-ease;
    span {
        display: block;
        background: $white;
        width: 22px;
        height: 3px;
        &:nth-of-type(n+2) {
            margin-top: 3px;
        }
    }
    &:hover, &:focus, &:active {
        background: $dark-green-dark;
    }
}

// OFF-CANVAS
$oc-size: 385px;
.off-canvas-wrapper {
  .off-canvas {
    transform: none;
    transition: left $offcanvas-transition-length $offcanvas-transition-timing, right $offcanvas-transition-length $offcanvas-transition-timing;
    background: $white;
    padding: 35px;
    left: -#{$oc-size};
    &.is-open {
        left: 0;
    }
    &.is-transition-push {
        box-shadow: 0px 0px 10px 0px rgba(0,0,0,.1);
    }
    .infos {
        line-height: 20px;
        font-size: 15px;
        p {
            line-height: 18px;
        }
        a {
            display: block;
            color: $black;
            @extend %transition-ease;
            &:nth-of-type(n+3) {
                color: $light-green;
                text-decoration: underline;
            }
            &:hover, &:focus, &:active {
                color: $grey;
                &:nth-of-type(n+3) {
                    color: $light-green-dark;
                }
            }
        }
        @include breakpoint (medium) {
            font-size: 18px;
            line-height: 24px;
        }
    }
    &.position-left {
      left: -#{$oc-size};
      &.is-open {
        left: 0;
      }
    }
    @include breakpoint (medium) {
        width: $oc-size;
    }
  }
}
.position-left {
  &.is-transition-push {
    & ~ .off-canvas-content {
      transform: none;
    }
  }
  &.is-open {
      & ~ .off-canvas-content {
          transform: none;
      }
      &+.js-off-canvas-overlay+.off-canvas-content .header-wrapper {
          left: 50px;
      }
  }
}
.js-off-canvas-overlay {
    background: rgba($black, .25);
}
.close {
    margin-bottom: calc(50px - .7rem);
    span {
        display: block;
        height: 17px;
        &:before {
            @include make-icon;
            @include icon-close;
            font-size: 17px;
            color: $dark-green;
            @extend %transition-ease;
        }
        &:hover, &:focus, &:active {
            &:before {
                color: $dark-green-darker;
            }
        }
    }
}
ul.menu {
    padding-bottom: calc(35px - .7rem);
    margin-bottom: 35px;
    border-bottom: 1px solid $light-green;
    li {
        width: 100%;
        list-style: none;
        a {
            display: block;
            font-family: $ff-asap;
            font-size: 15px;
            color: $black-blue;
            padding: .7rem 1rem .7rem 0;
            text-transform: uppercase;
            @extend %transition-ease;
            &:hover, &:focus, &:active {
                color: $grey;
            }
            &.is-active {
                color: $light-green;
            }
        }
    }
    @include breakpoint (medium) {
        li {
            a {
                font-size: 18px;
            }
        }
    }
}

// LOGO
.branding {
    // text-align: center;
    margin: 20px 0;
    a {
        display: inline-block;
        // width: 165px;
        width: 138px;
    }
    @include breakpoint (medium) {
        text-align: center;
        margin: 0;
    }
    @include breakpoint (xlarge) {
        padding: 0;
    }
}

// BUTTONS
.main-nav {
    display: inline-block;
    text-align: center;
    margin: 0 0 20px 0;
    .lang {
        color: $grey-darker;
        font-family: $font-b;
        padding: 10px;
        @extend %transition-ease;
        &:hover, &:focus, &:active {
            color: $grey;
        }
    }
    @include breakpoint (medium) {
        padding-right: .9375rem;
        padding-left: .9375rem;
        font-size: 0;
        text-align: right;
        margin: 0;
        .button-green {
            font-size: 16px;
        }
        .lang {
            font-size: 16px;
            padding: 10px 7px;
        }
    }
    @include breakpoint (xlarge) {
        padding: 0;
    }
    @media screen and (min-width: 780px) {
        .lang {
            padding: 20px 0 20px 20px;
        }
    }
}