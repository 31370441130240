.type-contact {
    // background-size: contain;
    .intro {
        padding: 0 20px;
        h1 {
            &:after {
                @include make-icon;
                @include icon-gimmick;
                display: block;
                color: $dark-green;
                margin-top: 25px;
            }
        }
        strong {
            line-height: 20px;
            font-size: 15px;
        }
        ul {
            li {
                font-size: 14px;
            }
        }
        .row > p {
            font-size: 14px;
        }
    }
    .form {
        padding: 0 20px;
        margin-top: 80px;
        h2 {
            text-align: center;
            margin: 0;
        }
        .error {
            color: red;
            border: 1px solid red;
        }
        .error:hover {
            border: 1px solid indianred;
        }
        .error:focus {
            border: 1px solid indianred;
        }
        .error-label {
            color: red;
            font-weight: bold;
        }
        .row {
            > p {
                text-align: center;
                line-height: 20px;
                font-size: 15px;
                margin: 0 20px;
                &:after {
                    @include make-icon;
                    @include icon-gimmick;
                    display: block;
                    color: $light-green;
                    margin: 10px 0 45px 0;
                }
            }
        }
        form {
            fieldset {
                 div label+ul{
                    color: red;
                    list-style: none;
                }
                &+fieldset {
                    margin-top: 40px;
                }
                div.columns {
                    padding: 0;
                }
                legend {
                    font-family: $font-b;
                    width: 100%;
                    font-size: 14px;
                    line-height: 18px;
                    position: relative;
                    small {
                        position: absolute;
                        top: 0;
                        right: 0;
                        color: $dark-green-dark;
                    }
                }
                p {
                    font-size: 14px;
                    line-height: 18px;
                    margin: 0;
                    color: $light-green;
                    &:nth-of-type(2) {
                        margin-top: 30px;
                    }
                }
                label {
                    span {
                        color: $dark-green-dark;
                    }
                    &.required {
                        &:after {
                            content: '*';
                            color: $light-green;
                            margin-left: 2px;
                        }
                    }
                }
                input {
                    box-shadow: none;
                    border-color: #c3c3c3;
                    &:hover, &:focus, &:active {
                        border-color: $light-green;
                    }
                }
                a {
                    display: block;
                    color: $dark-green;
                    text-decoration: underline;
                    margin: 20px 0 40px 0;
                    @extend %transition-ease;
                    &:hover, &:focus, &:active {
                        color: $dark-green-dark;
                    }
                    &:before {
                        content: '+';
                        display: inline-block;
                        margin-right: 5px;
                    }
                }
                #prix-recompense-concours-list,
                #stages-list,
                #experiences-list {
                    .input-wrapper {
                        &:nth-of-type(n+3) {
                            margin-top: 30px;
                            padding-top: 20px;
                            border-top: 1px solid $light-green;
                        }
                    }
                }
            }
            .g-recaptcha {
                margin-bottom: 40px;
            }
            input[type="submit"]{
                cursor: pointer;
            }
        }
    }
    @include breakpoint (medium) {
        .intro {
            h1 {
                margin-bottom: 48px;
            }
            strong {
                display: block;
                font-size: 18px;
                line-height: 24px;
                margin: 40px 0 10px 0;
            }
            ul {
                li {
                    font-size: 18px;
                }
            }
            .row > p {
                font-size: 18px;
                line-height: 24px;
            }
        }
        .form form {
            .row > p {
                font-size: 21px;
                line-height: inherit;
            }
            fieldset {
                #prix-recompense-concours-list,
                #stages-list,
                #experiences-list {
                    .input-wrapper {
                        div {
                            margin: 0;
                            > div {
                                display: inline-block;
                                width: 25%;
                                &:nth-of-type(2),
                                &:nth-of-type(3) {
                                    padding: 0 .9375rem;
                                }
                            }
                        }
                        &:nth-of-type(n+3) {
                            margin-top: 10px;
                            padding-top: 10px;
                        }
                    }
                }
                &:nth-child(-n+4) {
                    div.columns {
                        &:nth-of-type(odd) {
                            padding-left: 0;
                            padding-right: .9375rem;
                        }
                        &:nth-of-type(even) {
                            padding-right: 0;
                            padding-left: .9375rem;
                        }
                    }
                }
                &:nth-of-type(5),
                &:nth-of-type(6) {
                    div.columns {
                        &:nth-of-type(2),
                        &:nth-of-type(5) {
                            padding: 0 .9375rem;
                        }
                    }
                }
                &:nth-child(n+7) {
                    div.columns {
                        &:nth-of-type(2),
                        &:nth-of-type(3) {
                            padding: 0 .9375rem;
                        }
                    }
                }
            }
        }
    }
    @include breakpoint (large) {
        .intro {
            h1 {
                &:after {
                    font-size: 30px;
                }
            }
        }
    }
}