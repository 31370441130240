.button-green {
    display: inline-block;
    background: $dark-green;
    color: $white;
    text-transform: uppercase;
    padding: 7px 45px;
    font-family: $font-b;
    border: 0;
    @extend %transition-ease;
    &:hover, &:focus, &:active {
        background: $dark-green-dark;
        color: $white;
    }
    @media screen and (min-width: 640px) and (max-width: 780px) {
        padding: 7px 35px;
    }
}